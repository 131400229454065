import { JsonPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { DrawerComponent, OverlayService, RadioButtonGroup, RadioButtonGroupComponent } from "@cg/shared";
import { Reason } from "../../../../../interfaces/reason.interface";
import { CancelReasonComponent } from "../cancel-reason/cancel-reason.component";

@Component({
  selector: "cg-cancel-order-reason-list",
  templateUrl: "./cancel-order-reason-list.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RadioButtonGroupComponent, JsonPipe, ReactiveFormsModule, DrawerComponent]
})
export class CancelOrderReasonListComponent implements OnInit {
  public form: FormGroup;

  public radioButtons: RadioButtonGroup = {
    controlName: "reason",
    buttons: []
  };

  public reasons: Reason[] = [
    {
      text: "myCarglass.detail.overlays.reasonDescriptions.MC01",
      reasonId: "MC01"
    },
    {
      text: "myCarglass.detail.overlays.reasonDescriptions.MC02",
      reasonId: "MC02"
    },
    {
      text: "myCarglass.detail.overlays.reasonDescriptions.competitor",
      reasonId: "competitor", // only for fe usage
      subReasons: [
        {
          text: "myCarglass.detail.overlays.reasonDescriptions.MC03",
          reasonId: "MC03"
        },
        {
          text: "myCarglass.detail.overlays.reasonDescriptions.MC04",
          reasonId: "MC04"
        },
        {
          text: "myCarglass.detail.overlays.reasonDescriptions.MC05",
          reasonId: "MC05"
        }
      ]
    },
    {
      text: "myCarglass.detail.overlays.reasonDescriptions.MC07",
      reasonId: "MC07"
    },
    {
      text: "myCarglass.detail.overlays.reasonDescriptions.MC08",
      reasonId: "MC08"
    },
    {
      text: "myCarglass.detail.overlays.reasonDescriptions.MC09",
      reasonId: "MC09"
    },
    {
      text: "myCarglass.detail.overlays.reasonDescriptions.MC10",
      reasonId: "MC10"
    }
  ];

  public constructor(
    private readonly fb: FormBuilder,
    private readonly overlayService: OverlayService
  ) {}

  public ngOnInit(): void {
    this.initRadioButtons();
  }

  public selectionDone(selectedReason: string): void {
    if (selectedReason) {
      this.overlayService.open(CancelReasonComponent, {
        selectedReason: this.reasons.find((reason: Reason) => reason.reasonId === selectedReason)
      });
    }
  }

  private initRadioButtons(): void {
    this.form = this.fb.group({
      reason: [null, Validators.required]
    });

    this.radioButtons.buttons = this.reasons.map((reason: Reason) => ({
      title: reason.text,
      radio: {
        id: reason.reasonId,
        value: reason.reasonId
      }
    }));
  }
}
