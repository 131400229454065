import { type Action, createReducer, on } from "@ngrx/store";
import { MyCarglassNotificationStatus } from "@cg/my-carglass-login";
import type { CustomerEditDialog } from "../enum";
import type { CancelAppointmentStatus } from "../enum/CancelAppointmentStatus.enum";
import type { MyCarglassDetail } from "../interfaces";
import type { ServiceCenter } from "@cg/core/interfaces";
import type { AppointmentResponse, CustomerCase, CustomerCaseEditContact, SetAppointmentPayload } from "@cg/shared";
import * as DetailActions from "./detail.actions";

export const MYCARGLASS_DETAIL_FEATURE_KEY = "myCarglassDetail";

export interface DetailState {
  customerCaseEditContact: CustomerCaseEditContact;
  customerCase: CustomerCase;
  toastStatus: MyCarglassNotificationStatus | null;
  appointmentState: {
    nextLoadingLimitReached: boolean;
  };
  availableAppointments: AppointmentResponse;
  serviceCenterDetails: ServiceCenter;
  customerEditDialogVisibleType?: CustomerEditDialog;
  cancelAppointmentStatus: CancelAppointmentStatus | null;
  error?: string;
}

export interface DetailPartialState {
  readonly [MYCARGLASS_DETAIL_FEATURE_KEY]: DetailState;
}

export const initialState: DetailState = {
  customerCaseEditContact: null,
  customerCase: null,
  toastStatus: null,
  availableAppointments: null,
  appointmentState: null,
  serviceCenterDetails: null,
  customerEditDialogVisibleType: null,
  cancelAppointmentStatus: null
};

const detailReducer = createReducer(
  initialState,
  on(DetailActions.setMycarglassDetailObject, (state: DetailState, action: { payload: MyCarglassDetail }) => ({
    ...state,
    customerCase: action.payload.customerCase,
    customerCaseEditContact: action.payload.customerCaseEditContact
  })),
  on(
    DetailActions.updateAppointmentSuccessReceived,
    (state: DetailState, action: { payload: SetAppointmentPayload }) => {
      const updatedAppointment = action.payload;
      state = {
        ...state,
        customerCase: {
          ...state.customerCase,
          appointment: {
            ...state.customerCase.appointment,
            availabilityPeriodEnd: updatedAppointment.availabilityPeriodEnd,
            availabilityPeriodStart: updatedAppointment.availabilityPeriodStart,
            jobEnd: updatedAppointment.jobEnd,
            jobStart: updatedAppointment.jobStart,
            city: updatedAppointment.city,
            fitterId: updatedAppointment.fitterId,
            id: updatedAppointment.appointmentId,
            postalCode: updatedAppointment.postalCode,
            street: updatedAppointment.street,
            location: updatedAppointment.serviceCenter
          }
        },
        toastStatus: MyCarglassNotificationStatus.SUCCESS
      };
      return state;
    }
  ),
  on(
    DetailActions.updateCustomerContactSuccess,
    (state: DetailState, { payload }: { payload: CustomerCaseEditContact }) => ({
      ...state,
      customerCaseEditContact: payload,
      toastStatus: MyCarglassNotificationStatus.SUCCESS
    })
  ),
  on(DetailActions.updateCustomerContactFailure, (state: DetailState, { error }: { error: string }) => ({
    ...state,
    toastStatus: MyCarglassNotificationStatus.ERROR,
    error
  })),
  on(DetailActions.resetState, () => ({
    ...initialState
  })),
  on(DetailActions.setNotificationStatus, (state: DetailState, action: { payload: MyCarglassNotificationStatus }) => ({
    ...state,
    toastStatus: action.payload
  })),
  on(
    DetailActions.getServiceCentersDetailsSuccessReceived,
    (state: DetailState, action: { payload: ServiceCenter }) => ({
      ...state,
      serviceCenterDetails: action.payload
    })
  ),
  on(DetailActions.setCustomerEditDialogVisibleType, (state: DetailState, action: { payload: CustomerEditDialog }) => ({
    ...state,
    customerEditDialogVisibleType: action.payload
  })),
  on(DetailActions.cancelAppointmentStatus, (state: DetailState, action: { payload: CancelAppointmentStatus }) => ({
    ...state,
    cancelAppointmentStatus: action.payload
  })),
  on(DetailActions.getServiceCentersDetailsFailureReceived, (state: DetailState, action: { payload: string }) => ({
    ...state,
    error: action.payload
  }))
);

export function reducer(state: DetailState, action: Action): DetailState {
  return detailReducer(state, action);
}
