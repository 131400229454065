import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { PhoneNumber } from "@cg/shared";
import { BaseInfoOverlayComponent } from "../base-info-overlay/base-info-overlay.component";

@Component({
  selector: "cg-callout-appointment",
  templateUrl: "./callout-appointment.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, BaseInfoOverlayComponent]
})
export class CalloutAppointmentComponent {
  public readonly phone = PhoneNumber.MY_CARGLASS_DETAIL;
}
