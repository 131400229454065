import { Injectable } from "@angular/core";
import { combineLatest, Observable, of } from "rxjs";
import { map, take } from "rxjs/operators";
import { Feature, FeatureToggleFacade } from "@cg/feature-toggle";
import { appointmentChangeCarTypeList, CompromisedPart, CustomerCase, Damage } from "@cg/shared";

@Injectable({
  providedIn: "root"
})
export class AppointmentUtilityService {
  public constructor(private featureToggleFacade: FeatureToggleFacade) {}

  public isEditableCompromisedPart(part: CompromisedPart): boolean {
    return [
      CompromisedPart.FRONT,
      CompromisedPart.LEFT_SIDE,
      CompromisedPart.REAR,
      CompromisedPart.RIGHT_SIDE
    ].includes(part);
  }

  public isAppointmentChangeableInMyCarglass$(customerCase: CustomerCase): Observable<boolean> {
    const sideRearAppointmentChangeableFeature$ = this.featureToggleFacade.isFeatureActive$(
      Feature.SIDE_REAR_APPOINTMENT_CHANGEABLE
    );

    const otherConditions =
      customerCase.damages.length === 1 &&
      appointmentChangeCarTypeList.includes(customerCase.car.vehicleType) &&
      this.isEditableCompromisedPart(customerCase.damages[0].compromisedPart);

    const isSideOrRearWindow = [CompromisedPart.LEFT_SIDE, CompromisedPart.REAR, CompromisedPart.RIGHT_SIDE].includes(
      customerCase.damages[0].compromisedPart
    );

    return combineLatest([sideRearAppointmentChangeableFeature$, of(otherConditions)]).pipe(
      take(1),
      map(([isFeatureActive, otherConditionsMet]: [boolean, boolean]) => {
        if (isSideOrRearWindow) {
          return otherConditionsMet && isFeatureActive && customerCase.shoppingCartEntries?.length === 1;
        }

        return otherConditionsMet;
      })
    );
  }

  public isReplaceRequired(customerCase: CustomerCase): boolean {
    return customerCase.damages.some((damage: Damage) => damage.requiredService === "REPLACE");
  }
}
