import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { delay, filter, map, take } from "rxjs/operators";
import { CustomerCaseFacade } from "@cg/olb/state";
import { appointmentElements } from "@cg/translation";
import { TranslocoService } from "@jsverse/transloco";
import { calendarIcon, clockIcon, pinMapLineIcon } from "@cg/icon";
import {
  BreakpointService,
  ContentCard,
  CustomerCase,
  CustomerCaseCustomer,
  CustomerCaseEditContact,
  CustomerContactForm,
  EditCustomerContactComponent,
  FormatPhonePipe,
  Lpn,
  OverlayService
} from "@cg/shared";
import { DetailFacade } from "../+state/detail.facade";
import {
  EditAppointmentComponent,
  EditCustomerAddressComponent,
  NoContactEditingComponent
} from "../components/detail/components/my-carglass-overlays";
import { EditAppointmentByCallbackComponent } from "../components/detail/components/my-carglass-overlays/edit-appointment-by-callback/edit-appointment-by-callback.component";
import { CustomerEditDialog } from "../enum";
import { AppointmentUtilityService } from "./appointment-utility.service";
import { MyCarglassTrackingService } from "./my-carglass-tracking.service";

const detailsEventLabel = "my-carglass/detail";

@Injectable({
  providedIn: "root"
})
export class DetailService {
  public get isMobile$(): Observable<boolean> {
    return this.breakpointService.isTouch$;
  }

  private actionLabelNotPossible = "editing-not-possible";
  private readonly datePipe = new DatePipe("de-DE");

  public constructor(
    private readonly overlayService: OverlayService,
    private readonly breakpointService: BreakpointService,
    private readonly trackingService: MyCarglassTrackingService,
    private readonly detailFacade: DetailFacade,
    private readonly translocoService: TranslocoService,
    private readonly customerCaseFacade: CustomerCaseFacade,
    private readonly appointmentUtilityService: AppointmentUtilityService
  ) {}

  public createLocationContentCard(customerCase: CustomerCase, hasSeparatorLine: boolean): ContentCard {
    const { appointment } = customerCase;
    const locationContentCard = this.createContentCard(
      "location",
      "myCarglass.detail.serviceCenter",
      hasSeparatorLine,
      "",
      this.appointmentUtilityService.isReplaceRequired(customerCase) ? undefined : this.editAppointment(customerCase),
      { city: appointment.city }
    );
    locationContentCard.firstCellIcon = pinMapLineIcon;
    locationContentCard.subtitle = `${appointment.street}<br>${appointment.postalCode} ${appointment.city}`;
    return locationContentCard;
  }

  public createLpnContentCard(lpn: Lpn, hasSeparatorLine: boolean): ContentCard {
    return this.createContentCard(
      "lpn",
      "myCarglass.detail.lpn",
      hasSeparatorLine,
      `${lpn.region}-${lpn.letters}-${lpn.numbers}`
    );
  }

  public createAddressContentCard(
    customerCaseEditContact: CustomerCaseEditContact,
    hasSeparatorLine: boolean
  ): ContentCard {
    const address = customerCaseEditContact.address;
    return this.createContentCard(
      "address",
      "myCarglass.detail.address",
      hasSeparatorLine,
      `${customerCaseEditContact.firstName} ${customerCaseEditContact.lastName}</br>${address.street || ""}</br>${
        address.zipCode || ""
      } ${address.city || ""}`,
      () => {
        this.customerCaseFacade.isOrganization$.pipe(take(1)).subscribe((isOrganization: boolean) => {
          if (isOrganization) {
            this.trackingService.trackFieldEvent({
              eventAction: this.actionLabelNotPossible,
              eventLabel: "customer-address"
            });
            this.overlayService.open(NoContactEditingComponent);
            return;
          }

          const customer: Omit<
            CustomerCaseCustomer,
            "customerPhone1" | "customerEmail" | "contactFirstName" | "contactLastName"
          > = {
            customerTitle: customerCaseEditContact.title,
            customerFirstName: customerCaseEditContact.firstName,
            customerLastName: customerCaseEditContact.lastName,
            customerShipAddressLine2: customerCaseEditContact.address.street,
            customerShipCity: customerCaseEditContact.address.city,
            customerShipCountry: customerCaseEditContact.address.country,
            customerShipZipCode: customerCaseEditContact.address.zipCode,
            customerIsOrganization: isOrganization ? "Y" : "N"
          };

          this.trackingService.trackFieldEvent({ eventAction: "edit-customer-address", eventLabel: detailsEventLabel });
          this.overlayService.open(EditCustomerAddressComponent, {
            customer
          });

          this.resetCustomerEditDialogTypeAfterDelay();
          this.detailFacade.setCustomerEditDialogVisibleType(CustomerEditDialog.ADDRESS);
        });
      }
    );
  }

  public createDamageDateContentCard(damageDate: string, hasSeparatorLine: boolean): ContentCard {
    return this.createContentCard(
      "damageDate",
      "myCarglass.detail.damageDate",
      hasSeparatorLine,
      damageDate ? `${this.datePipe.transform(damageDate, "dd.MM.yyyy")}` : "--"
    );
  }

  public createToTakeWithContentCard$(damageDate: string, hasSeparatorLine: boolean): Observable<ContentCard> {
    const translationKeys = damageDate
      ? appointmentElements.mandatoryDocuments.items
      : appointmentElements.mandatoryDocumentsNoDamageDate.items;
    return combineLatest(
      translationKeys.map((translationKey: string) => this.translocoService.selectTranslate(translationKey))
    ).pipe(
      filter((translations: string[]) => translations.every(Boolean)),
      map((translations: string[]) => translations.map((translation: string) => `<li>${translation}</li>`)),
      map((listItems: string[]) => `<ul>${listItems.join("")}</ul>`),
      map((description: string) =>
        this.createContentCard(
          "toTakeWith",
          "appointmentConfirmation.texts.appointmentNeccessary",
          hasSeparatorLine,
          description
        )
      )
    );
  }

  public createContactContentCard(
    customerCaseEditContact: CustomerCaseEditContact,
    customerCaseId: string,
    hasSeparatorLine: boolean
  ): ContentCard {
    /*
    INFO: To edit the contact data of the customer, you can use the EditCustomerContactComponent. In this service, you can define a saveClicked Function,
    that calls DetailFacade.updateContactOfCustomer(..)
     */

    const formatPhonePipe = new FormatPhonePipe();
    const { phone } = customerCaseEditContact;
    const landline = phone.landline ? formatPhonePipe.transform(phone.landline, true) : "";
    const mobile = phone.mobile ? formatPhonePipe.transform(phone.mobile, true) : "";

    let phonePart = "";
    if (!mobile || !landline) {
      phonePart = mobile ? mobile : landline;
    } else if (mobile === landline) {
      phonePart = mobile;
    } else if (mobile !== landline) {
      phonePart = `${mobile}</br>${landline}`;
    }

    return this.createContentCard(
      "contact",
      "myCarglass.detail.contact",
      hasSeparatorLine,
      `${customerCaseEditContact.email}</br>${phonePart}`,
      () => {
        this.customerCaseFacade.isOrganization$.pipe(take(1)).subscribe((isOrganization: boolean) => {
          if (isOrganization) {
            this.trackingService.trackFieldEvent({
              eventAction: this.actionLabelNotPossible,
              eventLabel: "customer-contact"
            });
            this.overlayService.open(NoContactEditingComponent);
            return;
          }

          this.trackingService.trackFieldEvent({ eventAction: "edit-customer-contact", eventLabel: detailsEventLabel });
          this.overlayService.open(EditCustomerContactComponent, {
            customerCaseId,
            email: customerCaseEditContact.email,
            mobile: customerCaseEditContact.phone.mobile,
            landline: customerCaseEditContact.phone.landline,
            saveClicked: (contactForm: CustomerContactForm) => {
              this.detailFacade.updateContactOfCustomer(contactForm);
            }
          });

          this.resetCustomerEditDialogTypeAfterDelay();
          this.detailFacade.setCustomerEditDialogVisibleType(CustomerEditDialog.CONTACT);
        });
      }
    );
  }

  public createDateContentCard(customerCase: CustomerCase, hasSeparatorLine: boolean): ContentCard {
    const dateContentCard = this.createContentCard(
      "date",
      `${this.datePipe.transform(customerCase.appointment.availabilityPeriodStart, "EE")} ${this.datePipe.transform(
        customerCase.appointment.availabilityPeriodStart,
        "dd.MM.yyyy"
      )}`,
      hasSeparatorLine,
      "myCarglass.detail.appointmentTimeInfo",
      this.editAppointment(customerCase)
    );
    dateContentCard.firstCellIcon = calendarIcon;
    dateContentCard.subtitle = "myCarglass.detail.oclock";
    dateContentCard.subtitleTranslationParam = {
      time: this.datePipe.transform(customerCase.appointment.availabilityPeriodStart, "HH:mm")
    };
    return dateContentCard;
  }

  public createPaymentContentCard(): ContentCard {
    return this.createContentCard(
      "payment",
      "myCarglass.detail.payment",
      false,
      "myCarglass.detail.paymentDescription"
    );
  }

  public createOpeningHoursContentCard(): ContentCard {
    const openingHoursContentCard = this.createContentCard("openingHours", "", false);
    openingHoursContentCard.firstCellIcon = clockIcon;
    return openingHoursContentCard;
  }

  private createContentCard(
    id: string,
    title: string,
    hasSeparatorLine: boolean,
    description?: string,
    onEditClick?: () => void,
    descriptionTranslationParam?: Record<string, string>
  ): ContentCard {
    const contentCard: ContentCard = {
      id,
      title,
      description,
      hasSeparatorLine,
      editTextKey: "myCarglass.edit"
    };

    if (descriptionTranslationParam) {
      contentCard.descriptionTranslationParam = descriptionTranslationParam;
    }
    if (onEditClick) {
      contentCard.onEditClick = onEditClick;
    }
    return contentCard;
  }

  private editAppointment(customerCase: CustomerCase): () => void {
    return () => {
      this.appointmentUtilityService
        .isAppointmentChangeableInMyCarglass$(customerCase)
        .pipe(take(1))
        .subscribe((isChangeable: boolean) => {
          if (isChangeable) {
            this.overlayService.open(EditAppointmentComponent, { customerCase });
            this.trackingService.trackFieldEvent({ eventAction: "edit-appointment", eventLabel: detailsEventLabel });
          } else {
            this.overlayService.open(EditAppointmentByCallbackComponent, { customerCase });
            this.trackingService.trackFieldEvent({
              eventAction: this.actionLabelNotPossible,
              eventLabel: "appointment"
            });
          }
        });
    };
  }

  private resetCustomerEditDialogTypeAfterDelay() {
    // needs to wait a few ms so value is reset after tracking events are triggered.
    this.overlayService.afterClosed$.pipe(take(1), delay(500)).subscribe(() => {
      this.detailFacade.setCustomerEditDialogVisibleType(null);
    });
  }
}
