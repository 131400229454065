import { Component } from "@angular/core";
import { TranslocoModule } from "@jsverse/transloco";
import { HeadlineComponent, IconComponent } from "@cg/core/ui";
import { calendarCancel } from "@cg/icon";
import { BaseButtonComponent, DialogSuccessComponent, DrawerComponent, OverlayService } from "@cg/shared";
import { DetailFacade } from "../../../../../+state/detail.facade";

@Component({
  selector: "cg-cancel-appointment-success",
  standalone: true,
  imports: [
    DrawerComponent,
    BaseButtonComponent,
    TranslocoModule,
    IconComponent,
    HeadlineComponent,
    DialogSuccessComponent
  ],
  templateUrl: "./cancel-appointment-success.component.html"
})
export class CancelAppointmentSuccessComponent {
  public readonly calendarCancel = calendarCancel;
  public readonly description = "myCarglass.detail.overlays.cancelSuccess.paragraph";
  public readonly buttonText = "myCarglass.detail.overlays.cancelSuccess.backToHome";

  public constructor(
    private readonly overlayService: OverlayService,
    private readonly detailFacade: DetailFacade
  ) {}

  public navigateToHomepage(): void {
    this.overlayService.close();
    this.detailFacade.logout({ isAutoLogout: false, customRouting: [""] });
  }
}
