import { distinctUntilChanged, map, scan } from "rxjs/operators";
import type { Observable } from "rxjs";

export function wasDataChanged(): (src: Observable<unknown>) => Observable<boolean> {
  return (src: Observable<unknown>) =>
    src.pipe(
      scan(
        (
          acc: { wasChanged: boolean; data: unknown; initial: boolean },
          curr: { wasChanged: boolean; data: unknown; initial: boolean }
        ) => ({
          wasChanged: acc.wasChanged || (!acc.initial && JSON.stringify(acc.data) !== JSON.stringify(curr)),
          data: curr,
          initial: false
        }),
        {
          wasChanged: false,
          data: null,
          initial: true
        }
      ),
      map(({ wasChanged }: { wasChanged: boolean }) => wasChanged),
      distinctUntilChanged()
    );
}
