<cg-drawer [headline]="headline" [content]="content" [footer]="footer" [showCloseIcon]="false"></cg-drawer>

<ng-template #headline>
  <cg-olb-headline [title]="'myCarglass.detail.overlays.customerAddressTitle' | transloco"> </cg-olb-headline>
</ng-template>
<ng-template #content>
  <form class="mt-4" [formGroup]="form">
    <cg-address-form></cg-address-form>
  </form>
</ng-template>
<ng-template #footer>
  <cg-overlay-footer (submitButtonClicked)="saveForm()"></cg-overlay-footer>
</ng-template>
