import { Injectable } from "@angular/core";
import { damageWindowTrackingMapping } from "@cg/olb/tiles";
import { differenceInCalendarDays, differenceInHours } from "date-fns";
import { GoogleTagManagerService } from "@cg/analytics";
import { DamageWindow, toCompromisedPart } from "@cg/shared";
import { MyCarglassTrackingEvent } from "../models";
import { AppointmentUtilityService } from "./appointment-utility.service";

const detailEventLabel = "my-carglass/detail";

@Injectable({
  providedIn: "root"
})
export class MyCarglassTrackingService {
  public constructor(
    private readonly gtmService: GoogleTagManagerService,
    private readonly appointmentUtilityService: AppointmentUtilityService
  ) {}

  public trackFieldEvent(eventData: MyCarglassTrackingEvent): void {
    this.trackEvent(eventData);
  }

  public trackEditCustomer(eventAction: string, startDate: string): void {
    this.trackEvent({
      eventAction,
      eventLabel: detailEventLabel,
      appointment: {
        "days-until": Math.abs(this.getDifferenceInDays(startDate))
      }
    });
  }

  public trackAppointmentDetails(damageWindow: DamageWindow, requiredService: "repair" | "replace"): void {
    this.trackEvent({
      eventLabel: this.getEventLabelForAppointmentDetails(damageWindow),
      eventAction: damageWindowTrackingMapping.get(damageWindow),
      appointment: {
        "job-type": requiredService
      }
    });
  }

  public trackEditAppointment(eventAction: string, startDate: string, endDate?: string): void {
    const getAppointnment = () => {
      switch (eventAction) {
        case "appointment-edited":
          return this.getAppointmentEdited(startDate, endDate);
        case "cancel-appointment-success":
          return this.getCancelAppointments(startDate);
        default:
          return {
            "days-until": Math.abs(this.getDifferenceInDays(startDate))
          };
      }
    };

    this.trackEvent({
      eventAction,
      eventLabel: detailEventLabel,
      appointment: getAppointnment()
    });
  }

  public trackCancelAppointmentIntent(): void {
    this.trackEvent({
      eventAction: "cancel-appointment",
      eventLabel: detailEventLabel
    });
  }

  private getEventLabelForAppointmentDetails(damageWindow: DamageWindow): string {
    const compromisedPart = toCompromisedPart[damageWindow];
    return this.appointmentUtilityService.isEditableCompromisedPart(compromisedPart)
      ? "editing-possible"
      : "editing-not-possible";
  }

  private trackEvent(eventData: MyCarglassTrackingEvent): void {
    this.gtmService.trackEvent({
      ...eventData,
      eventCategory: "my-carglass"
    });
  }

  private getDifferenceInDays(appointmentDate: string, oldAppointmentDate?: string): number {
    return differenceInCalendarDays(
      new Date(appointmentDate),
      new Date(oldAppointmentDate ? oldAppointmentDate : new Date(Date.now()))
    );
  }

  private getCancelAppointments(startDate: string): Record<string, number> {
    const daysDifference = this.getDifferenceInDays(startDate);
    const appointment = {
      "canceled-days-before": daysDifference
    };

    if (daysDifference === 0) {
      appointment["canceled-hours-before"] = Math.abs(differenceInHours(new Date(startDate), new Date(Date.now())));
    }

    return appointment;
  }

  private getAppointmentEdited(startDate: string, endDate: string): Record<string, number | string> {
    const daysChanged = this.getDifferenceInDays(startDate, endDate);

    return {
      "days-until": Math.abs(this.getDifferenceInDays(startDate)),
      "days-changed-to-appointment": Math.abs(daysChanged),
      edited: daysChanged < 0 ? "earlier-date" : "later-date"
    };
  }
}
