import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { distinctUntilChanged, tap, withLatestFrom } from "rxjs/operators";
import { CustomerCase, eventRequiredServiceMapping, RequiredService, toDamageWindow } from "@cg/shared";
import { CustomerEditDialog } from "../enum";
import { AppointmentCancelation } from "../interfaces/appointment-cancelation.interface";
import { MyCarglassUpdateAppointment } from "../interfaces/my-carglass-update-appointment.interface";
import { MyCarglassTrackingService } from "../services";
import * as DetailActions from "./detail.actions";
import { DetailFacade } from "./detail.facade";
import * as DetailSelectors from "./detail.selectors";

@Injectable()
export class TrackingEffects {
  public updateAppointment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DetailActions.updateAppointment),
        tap((action: { payload: MyCarglassUpdateAppointment } & Action) => {
          this.trackingService.trackEditAppointment(
            "appointment-edited",
            action.payload.setAppointmentPayload.availabilityPeriodStart,
            action.payload.prevAppointmentStartDate
          );
        })
      ),
    { dispatch: false }
  );

  public cancelAppointment$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DetailActions.cancelAppointment),
        withLatestFrom(this.detailFacade.customerCase$),
        tap(([_action, customerCase]: [{ payload: AppointmentCancelation }, CustomerCase]) => {
          this.trackingService.trackEditAppointment(
            "cancel-appointment-success",
            customerCase.appointment.availabilityPeriodStart
          );
        })
      ),
    { dispatch: false }
  );

  public trackAppointmentDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DetailActions.setMycarglassDetailObject),
        withLatestFrom(this.store.pipe(select(DetailSelectors.getCustomerCase))),
        distinctUntilChanged(
          (
            [_prevAction, prevCustomerCase]: [Action, CustomerCase],
            [_curAction, curCustomerCase]: [Action, CustomerCase]
          ) => prevCustomerCase.id === curCustomerCase.id
        ),
        tap(([_action, customerCase]: [Action, CustomerCase]) => {
          this.trackingService.trackAppointmentDetails(
            toDamageWindow[customerCase.damages[0].compromisedPart],
            eventRequiredServiceMapping.get(customerCase.damages[0].requiredService as RequiredService)
          );
        })
      ),
    { dispatch: false }
  );

  public updateCustomerContact$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DetailActions.updateCustomerContactSuccess),
        withLatestFrom(
          this.store.pipe(select(DetailSelectors.getCustomerCase)),
          this.store.pipe(select(DetailSelectors.getCustomerEditDialogVisibleType))
        ),
        tap(([_action, customerCase, editDialogVisible]: [Action, CustomerCase, CustomerEditDialog]) => {
          this.trackingService.trackEditCustomer(
            editDialogVisible === CustomerEditDialog.CONTACT ? "customer-contact-edited" : "customer-address-edited",
            customerCase.appointment.availabilityPeriodStart
          );
        })
      ),
    { dispatch: false }
  );

  public constructor(
    private readonly actions$: Actions,
    private readonly trackingService: MyCarglassTrackingService,
    private readonly detailFacade: DetailFacade,
    private readonly store: Store
  ) {}
}
