import { NgClass } from "@angular/common";
import { Component, DestroyRef, inject, Input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { filter, tap } from "rxjs/operators";
import { TranslocoPipe } from "@jsverse/transloco";
import { FakeDropdownComponent, HeadlineComponent, HeadlineType, IconComponent } from "@cg/core/ui";
import { errorIcon } from "@cg/icon";
import {
  BaseButtonComponent,
  DrawerComponent,
  OverlayService,
  RadioButtonGroup,
  RadioButtonGroupComponent
} from "@cg/shared";
import { DetailFacade } from "../../../../../+state/detail.facade";
import { CancelAppointmentStatus } from "../../../../../enum/CancelAppointmentStatus.enum";
import { Reason } from "../../../../../interfaces/reason.interface";
import { CancelAppointmentErrorComponent } from "../cancel-appointment-error/cancel-appointment-error.component";
import { CancelOrderReasonListComponent } from "../cancel-order-reason-list/cancel-order-reason-list.component";

@Component({
  selector: "cg-cancel-reason",
  standalone: true,
  imports: [
    NgClass,
    FakeDropdownComponent,
    RadioButtonGroupComponent,
    ReactiveFormsModule,
    DrawerComponent,
    BaseButtonComponent,
    HeadlineComponent,
    IconComponent,
    TranslocoPipe
  ],
  templateUrl: "./cancel-reason.component.html"
})
export class CancelReasonComponent implements OnInit {
  private static readonly EMPTY_REASON: string = "";

  @Input()
  public selectedReason: Reason;

  public feedbackErrorIcon = errorIcon;
  public radioButtons: RadioButtonGroup = {
    controlName: "subReason",
    buttons: []
  };
  public showError = false;
  public form: FormGroup;

  protected readonly headlineType = HeadlineType;
  private destroyRef: DestroyRef = inject(DestroyRef);

  public constructor(
    private readonly fb: FormBuilder,
    private readonly overlayService: OverlayService,
    private readonly detailFacade: DetailFacade
  ) {}

  public ngOnInit(): void {
    this.initRadioButtons();
    this.initListenToCancellationStatus();
  }

  public openSelectionDialog(): void {
    this.overlayService.open(CancelOrderReasonListComponent, {});
  }

  public onSubSelection(): void {
    this.showError = false;
  }

  public completeCancellation(): void {
    if (!this.form.get("subReason").errors) {
      this.detailFacade.cancelAppointment(this.form.get("subReason").value, CancelReasonComponent.EMPTY_REASON);
    } else {
      this.showError = true;
    }
  }

  private initRadioButtons(): void {
    this.form = this.fb.group({
      subReason: [null, Validators.required]
    });
    if (this.selectedReason) {
      if (this.selectedReason.subReasons) {
        this.radioButtons.buttons = this.selectedReason.subReasons.map((reason: Reason) => ({
          title: reason.text,
          radio: {
            id: reason.reasonId,
            value: reason.reasonId
          }
        }));
      } else {
        this.form.get("subReason").setValue(this.selectedReason.reasonId);
      }
    }
  }

  private initListenToCancellationStatus(): void {
    this.detailFacade.cancelAppointmentStatus$
      .pipe(
        filter((status: CancelAppointmentStatus) => !!status),
        tap((status: CancelAppointmentStatus) => {
          if (status === CancelAppointmentStatus.SUCCESS) {
            this.detailFacade.logout({ isCancelLogout: true });
          } else {
            this.overlayService.open(CancelAppointmentErrorComponent, null, {
              positionByBreakpoints: OverlayService.POSITION_M_CENTER
            });
          }
          this.detailFacade.setCancelAppointmentStatus(null);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
