import { provideEffects } from "@ngrx/effects";
import { provideState } from "@ngrx/store";
import { AppointmentEffects, CustomerCaseEffects, ProductEffects } from "@cg/olb/effects";
import { CookieService } from "ngx-cookie-service";
import { provideMyCarglassLogin } from "@cg/my-carglass-login";
import { DetailEffects } from "./+state/detail.effects";
import * as fromDetail from "./+state/detail.reducer";
import { MYCARGLASS_DETAIL_FEATURE_KEY } from "./+state/detail.reducer";
import { TrackingEffects } from "./+state/tracking.effects";

export function provideMyCarglass() {
  return [
    provideMyCarglassLogin(),
    provideState(MYCARGLASS_DETAIL_FEATURE_KEY, fromDetail.reducer),
    provideEffects([DetailEffects, AppointmentEffects, ProductEffects, CustomerCaseEffects, TrackingEffects]),
    CookieService
  ];
}
