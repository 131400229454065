<cg-drawer
  class="[&>div>div]:p-0 [&>div]:p-6 t:[&>div]:m-0 t:[&>div]:max-w-xs d:[&>div]:max-w-[400px] d:[&>div]:p-10"
  [content]="content"
></cg-drawer>

<ng-template #content>
  <form class="mt-4" [formGroup]="form">
    <cg-radio-button-group
      [formControlName]="radioButtons.controlName"
      [content]="radioButtons"
      (selectedValueChanged)="selectionDone($event)"
    ></cg-radio-button-group>
  </form>
</ng-template>
