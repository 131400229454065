import { ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit } from "@angular/core";
import { take } from "rxjs";
import { ToastMessage, ToastMessageComponent } from "@cg/toast";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { IconComponent, ParagraphComponent } from "@cg/core/ui";
import { calendarCancelIcon, cccAgentSmallIcon, infoIcon, phoneIcon } from "@cg/icon";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import {
  BaseButtonComponent,
  CustomerCase,
  DrawerComponent,
  OverlayService,
  PhoneNumber,
  SelfCallDialogComponent
} from "@cg/shared";
import type { Paragraph } from "@cg/content-api/typescript-interfaces";
import { AppointmentUtilityService } from "../../../../../services/appointment-utility.service";
import { MyCarglassTrackingService } from "../../../../../services/my-carglass-tracking.service";
import { BaseInfoOverlayComponent } from "../base-info-overlay/base-info-overlay.component";
import { CancelReasonComponent } from "../cancel-reason/cancel-reason.component";
import { EditAppointmentComponent } from "../edit-appointment/edit-appointment.component";
import { EditAppointmentByCallbackComponent } from "../edit-appointment-by-callback/edit-appointment-by-callback.component";

@Component({
  selector: "cg-cancel-appointment",
  templateUrl: "./cancel-appointment.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    BaseInfoOverlayComponent,
    DrawerComponent,
    OlbHeadlineComponent,
    ParagraphComponent,
    ToastMessageComponent,
    BaseButtonComponent,
    TranslocoPipe,
    IconComponent
  ]
})
export class CancelAppointmentComponent implements OnInit {
  public readonly PhoneNumber = PhoneNumber;

  public destroyRef = inject(DestroyRef);
  public descriptionText: Paragraph;
  public separatorAlternative: Paragraph;
  public hintText: ToastMessage;

  @Input()
  public customerCase: CustomerCase;

  public readonly calendarCancelIcon = calendarCancelIcon;
  public readonly iconInfo = infoIcon;
  public readonly iconPhone = phoneIcon;
  protected readonly cccAgentSmallIcon = cccAgentSmallIcon;

  public constructor(
    private readonly overlayService: OverlayService,
    private readonly mycarglassTrackingService: MyCarglassTrackingService,
    private readonly translocoService: TranslocoService,
    private readonly appointmentUtilityService: AppointmentUtilityService
  ) {}

  public ngOnInit(): void {
    this.initTranslation();
  }

  public cancelAppointment(): void {
    this.overlayService.open(CancelReasonComponent);
  }

  public changeAppointment(): void {
    this.mycarglassTrackingService.trackEditAppointment(
      "edit-appointment",
      this.customerCase.appointment.availabilityPeriodStart
    );
    this.overlayService.close();

    this.appointmentUtilityService
      .isAppointmentChangeableInMyCarglass$(this.customerCase)
      .pipe(take(1))
      .subscribe((isChangeable: boolean) => {
        this.overlayService.open(isChangeable ? EditAppointmentComponent : EditAppointmentByCallbackComponent, {
          customerCase: this.customerCase
        });
      });
  }

  public openCallDialog(): void {
    this.overlayService.open(
      SelfCallDialogComponent,
      { localPhoneNumber: PhoneNumber.MY_CARGLASS_CANCEL },
      { positionByBreakpoints: OverlayService.POSITION_M_CENTER }
    );
  }

  private initTranslation() {
    const translations: Record<string, string> = this.translocoService.translateObject(
      "myCarglass.detail.overlays.cancelAppointment"
    );
    this.descriptionText = this.createParagraph(translations["descriptionText"]);
    this.separatorAlternative = this.createParagraph(translations["separatorAlternative"]);
    this.hintText = this.createToastMessage(translations["hintText"]);
  }

  private createToastMessage(text: string): ToastMessage {
    return {
      message: text,
      color: "#FFDC00",
      icon: this.iconInfo,
      roundedCorners: true
    };
  }

  private createParagraph(text: string): Paragraph {
    return {
      text,
      ngTemplate: "cgParagraph"
    };
  }
}
