import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { logoutIcon } from "@cg/icon";
import { OverlayService } from "@cg/shared";
import { DetailFacade } from "../../../../../+state/detail.facade";
import { BaseInfoOverlayComponent } from "../base-info-overlay/base-info-overlay.component";

@Component({
  selector: "cg-browser-back-overlay",
  templateUrl: "./browser-back-overlay.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslocoPipe, BaseInfoOverlayComponent]
})
export class BrowserBackOverlayComponent {
  public readonly logoutIcon = logoutIcon;

  public constructor(
    private readonly detailFacade: DetailFacade,
    private readonly overlayService: OverlayService
  ) {}

  public logoutButtonClicked(): void {
    this.detailFacade.logout();
    this.overlayService.close();
  }
}
