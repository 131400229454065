<cg-drawer
  class="[&>div>div]:p-0 [&>div]:p-6 t:[&>div]:m-0 t:[&>div]:max-w-xs d:[&>div]:max-w-[400px] d:[&>div]:p-10"
  [content]="content"
></cg-drawer>

<ng-template #content>
  <div class="flex flex-col">
    <cg-headline
      class="[&>h2]:leading-inherit mb-4 block t:[&>h2]:text-[1.625rem] t:[&>h2]:leading-8 d:[&>h2]:text-[1.875rem] d:[&>h2]:leading-10"
      [type]="headlineType.h2"
      [content]="'myCarglass.detail.overlays.cancelReason.title' | transloco"
    ></cg-headline>
    <cg-fake-dropdown
      class="mb-6 block"
      [error]="showError && !selectedReason"
      [placeholder]="
        (selectedReason ? selectedReason.text : 'myCarglass.detail.overlays.cancelReason.dropdownPlaceholder')
          | transloco
      "
      [hasSelection]="!!selectedReason"
      (press)="openSelectionDialog()"
    ></cg-fake-dropdown>
    @if (selectedReason?.subReasons) {
      <form [formGroup]="form">
        <cg-radio-button-group
          class="mb-6 block [&>div>div>label]:bg-white [&>div>div>label]:!p-0 [&>div>div>label]:hover:bg-white [&>div>div]:mb-0 [&>div]:flex [&>div]:flex-col [&>div]:gap-4"
          [formControlName]="radioButtons.controlName"
          [content]="radioButtons"
          (selectedValueChanged)="onSubSelection()"
        ></cg-radio-button-group>
      </form>
    }
    @if (showError) {
      <div class="-mt-5 mb-6 flex flex-row gap-0.5">
        <cg-icon class="h-5 w-5" [content]="feedbackErrorIcon"></cg-icon>
        <span class="text-cg-red">{{ "myCarglass.detail.overlays.cancelReason.error" | transloco }}</span>
      </div>
    }
    <button
      cg-base-button
      [btnText]="'myCarglass.detail.overlays.cancelReason.successButton' | transloco"
      (click)="completeCancellation()"
    ></button>
  </div>
</ng-template>
