import { createFeatureSelector, createSelector } from "@ngrx/store";
import type { GenericOpeningHours, OpeningHours, ServiceCenter } from "@cg/core/interfaces";
import type { WeekDay } from "@cg/core/types";
import type { CustomerCase, CustomerCaseAppointment } from "@cg/shared";
import { type DetailState, MYCARGLASS_DETAIL_FEATURE_KEY } from "./detail.reducer";

const mapDayToNumber = new Map<WeekDay, number>([
  ["Montag", 1],
  ["Dienstag", 2],
  ["Mittwoch", 3],
  ["Donnerstag", 4],
  ["Freitag", 5],
  ["Samstag", 6],
  ["MONDAY", 1],
  ["TUESDAY", 2],
  ["WEDNESDAY", 3],
  ["THURSDAY", 4],
  ["FRIDAY", 5],
  ["SATURDAY", 6]
]);

export const getDetailState = createFeatureSelector<DetailState>(MYCARGLASS_DETAIL_FEATURE_KEY);

export const getCar = createSelector(getDetailState, (state: DetailState) => state?.customerCase.car ?? null);

export const getToastStatus = createSelector(getDetailState, (state: DetailState) =>
  state ? state.toastStatus : null
);

export const getCustomerCase = createSelector(getDetailState, (state: DetailState) =>
  state ? state.customerCase : null
);

export const getCustomerCaseEditContact = createSelector(
  getDetailState,
  (state: DetailState) => state?.customerCaseEditContact ?? null
);

export const getAppointment = createSelector(
  getCustomerCase,
  (customerCase: CustomerCase) => customerCase?.appointment ?? null
);

export const getDamages = createSelector(
  getCustomerCase,
  (customerCase: CustomerCase) => customerCase?.damages ?? null
);

export const getServiceCenterDetails = createSelector(getDetailState, (state: DetailState) =>
  state ? state.serviceCenterDetails : null
);

export const getCustomerEditDialogVisibleType = createSelector(getDetailState, (state: DetailState) =>
  state ? state.customerEditDialogVisibleType : null
);

export const getOpeningHours = createSelector(
  getServiceCenterDetails,
  getAppointment,
  (serviceCenter: ServiceCenter, appointment: CustomerCaseAppointment) => {
    if (!serviceCenter || !appointment) {
      return null;
    }

    const mappedOpeningHoursList: GenericOpeningHours[] = [];
    const dateOfAppointment = new Date(appointment.jobStart);
    let openingHoursSelectedDate;

    serviceCenter.openingHours.forEach((scOpeningHours: OpeningHours) => {
      const dayAsNumber = mapDayToNumber.get(scOpeningHours.day);
      const appointmentDay = dayAsNumber === dateOfAppointment.getDay();
      const openingHours = {
        day: scOpeningHours.day,
        currentDay: appointmentDay,
        dayOpen: !!scOpeningHours.start && !!scOpeningHours.end,
        from: scOpeningHours.start,
        to: scOpeningHours.breakStart || scOpeningHours.end,
        from2: scOpeningHours.breakEnd || null,
        to2: scOpeningHours.breakStart ? scOpeningHours.end : null
      };
      mappedOpeningHoursList.push(openingHours);

      if (appointmentDay) {
        openingHoursSelectedDate = openingHours;
      }
    });

    return { allOpeningHours: mappedOpeningHoursList, appointmentDayOpeningHours: openingHoursSelectedDate };
  }
);

export const getCancelAppointmentStatus = createSelector(getDetailState, (state: DetailState) =>
  state ? state.cancelAppointmentStatus : null
);
