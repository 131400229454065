import { AsyncPipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostListener,
  inject,
  OnDestroy,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { NavigationStart, Router } from "@angular/router";
import { combineLatest, Observable } from "rxjs";
import { filter, map, tap } from "rxjs/operators";
import { SpinnerComponent } from "@cg/spinner";
import { addSeconds } from "date-fns/fp";
import { CookieService } from "ngx-cookie-service";
import {
  MY_CARGLASS_DETAIL_URL,
  MY_CARGLASS_LOGIN_URL,
  MY_CARGLASS_LOGOUT_URL,
  MyCarglassNotificationStatus,
  MyCarglassStatusToastComponent
} from "@cg/my-carglass-login";
import {
  CustomerCase,
  CustomerCaseAppointment,
  CustomerCaseCar,
  CustomerCaseEditContact,
  Damage,
  OverlayService
} from "@cg/shared";
import { DetailFacade } from "../../+state/detail.facade";
import { AutoLogoutService } from "../../services";
import { MyCarglassActionsComponent } from "./components/my-carglass-actions/my-carglass-actions.component";
import { MyCarglassBookedAppointmentComponent } from "./components/my-carglass-booked-appointment/my-carglass-booked-appointment.component";
import { MyCarglassBookedDetailsComponent } from "./components/my-carglass-booked-details/my-carglass-booked-details.component";
import { MyCarglassDetailHeaderComponent } from "./components/my-carglass-detail-header/my-carglass-detail-header.component";
import { BrowserBackOverlayComponent } from "./components/my-carglass-overlays";

@Component({
  selector: "cg-detail",
  templateUrl: "./my-carglass-detail-page-wrapper.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    MyCarglassDetailHeaderComponent,
    MyCarglassBookedAppointmentComponent,
    MyCarglassBookedDetailsComponent,
    MyCarglassActionsComponent,
    MyCarglassStatusToastComponent,
    SpinnerComponent
  ]
})
export class MyCarglassDetailPageWrapperComponent implements OnInit, OnDestroy {
  public destroyRef = inject(DestroyRef);
  public customerCaseEditContact: CustomerCaseEditContact;
  public customerCase: CustomerCase;
  public customerCaseId: string;
  public customerCaseDamage: Damage;
  public car: CustomerCaseCar;
  public damages: Damage[];
  public appointment: CustomerCaseAppointment;
  public customerCaseLoaded = false;
  public notificationStatus$: Observable<MyCarglassNotificationStatus>;

  @HostListener("window:beforeunload", ["$event"]) public refreshHandler(): void {}

  public constructor(
    private readonly detailFacade: DetailFacade,
    private readonly cdr: ChangeDetectorRef,
    private readonly cookieService: CookieService,
    private readonly autoLogoutService: AutoLogoutService,
    private readonly router: Router,
    private readonly overlayService: OverlayService
  ) {}

  public ngOnInit(): void {
    this.refreshHandler = () => {
      this.cookieService.set("customerCaseId", this.customerCaseId, addSeconds(5, new Date(Date.now())));
    };
    this.autoLogoutService.initAutologoutFunctionality();

    combineLatest([this.detailFacade.customerCaseEditContact$, this.detailFacade.customerCase$])
      .pipe(
        filter(
          ([customerCaseEditContact, customerCase]: [CustomerCaseEditContact, CustomerCase]) =>
            !!customerCaseEditContact && customerCase?.damages.length > 0
        ),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(([customerCaseEditContact, customerCase]: [CustomerCaseEditContact, CustomerCase]) => {
        this.customerCaseEditContact = customerCaseEditContact;
        this.detailFacade.getServiceCentersDetails(customerCase.appointment.location);
        this.appointment = customerCase.appointment;
        this.customerCase = customerCase;
        this.customerCaseId = customerCase.id;
        this.damages = customerCase.damages;
        this.customerCaseDamage = customerCase.damages[0];
        this.car = customerCase.car;
        this.customerCaseLoaded = true;
        this.cdr.markForCheck();
      });

    this.initializeToastStatus();
    this.initializeRouterEventListener();
  }

  public ngOnDestroy(): void {
    this.overlayService.close();
    this.autoLogoutService.clearIntervalAndRemoveListener();
    this.refreshHandler = () => {};
  }

  private initializeToastStatus(): void {
    this.notificationStatus$ = this.detailFacade.notificationStatus$.pipe(
      tap((toastStatus: MyCarglassNotificationStatus): void => {
        if (toastStatus) {
          setTimeout(() => {
            this.detailFacade.setNotificationStatus(null);
          }, 4000);
        }
      }),
      map((toastStatus: MyCarglassNotificationStatus) => toastStatus),
      takeUntilDestroyed(this.destroyRef)
    );
  }

  private initializeRouterEventListener(): void {
    this.router.events
      .pipe(
        filter((event: unknown) => event instanceof NavigationStart),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((event: NavigationStart) => {
        if (event.url === MY_CARGLASS_LOGOUT_URL) {
          this.detailFacade.logout();
        } else if (event.url === MY_CARGLASS_LOGIN_URL && event.navigationTrigger === "popstate") {
          this.overlayService.open(BrowserBackOverlayComponent, {});
          this.router.navigate([MY_CARGLASS_DETAIL_URL]).then(() => {});
        }
      });
  }
}
