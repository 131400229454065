<cg-drawer [showCloseIcon]="true" [icon]="icon" [headline]="headline" [content]="content" [footer]="footer"></cg-drawer>

<ng-template #headline>
  <cg-olb-headline data-cy="base-info-headline" [title]="title"></cg-olb-headline>
</ng-template>

<ng-template #content>
  <div class="flex flex-col items-center gap-3">
    @if (text) {
      <cg-paragraph [content]="text"></cg-paragraph>
    }
    @if (phone) {
      <cg-ccc-phone-info class="[&>div]:mb-0" [phoneNumber]="phone"></cg-ccc-phone-info>
    }
  </div>
</ng-template>

<ng-template #footer>
  <cg-overlay-footer
    [submitButtonTranslationKey]="submitButtonTranslationKey"
    (submitButtonClicked)="emitSubmitButtonClicked()"
    [hideSaveBtn]="hideSaveBtn"
    [cancelCta]="cancelCta"
  ></cg-overlay-footer>
</ng-template>
