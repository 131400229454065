<div class="bg-cg-gray-light-70">
  @if (customerCaseLoaded) {
    <div class="mx-auto my-0 flex h-full max-w-[1200px] flex-col gap-6 px-2 py-6 m:px-4 t:px-6 d:px-8 d:py-12 w:px-12">
      @if (notificationStatus$ | async; as toastStatus) {
        <cg-my-carglass-status-toast
          class="fixed left-1/2 z-10 -translate-x-1/2 [&>cg-toast-popup>div]:relative"
          [toastStatus]="toastStatus"
        ></cg-my-carglass-status-toast>
      }
      <cg-my-carglass-detail-header
        [damages]="customerCase.damages"
        [customerName]="customerCaseEditContact.name"
        [orderNumber]="customerCase.orderNumber ?? null"
      ></cg-my-carglass-detail-header>
      <cg-my-carglass-booked-appointment [customerCase]="customerCase"></cg-my-carglass-booked-appointment>
      <cg-my-carglass-booked-details
        [damages]="damages"
        [requiredService]="customerCaseDamage.requiredService"
        [car]="car"
        [customerCaseEditContact]="customerCaseEditContact"
        [customerCaseId]="customerCaseId"
        [appointment]="appointment"
      >
      </cg-my-carglass-booked-details>
      <cg-my-carglass-actions [customerCase]="customerCase"></cg-my-carglass-actions>
    </div>
  }
</div>
<cg-spinner></cg-spinner>
