import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { TranslocoPipe } from "@jsverse/transloco";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { AddressFormComponent, CustomerCaseCustomer, DrawerComponent, OverlayFooterComponent } from "@cg/shared";
import { DetailFacade } from "../../../../../+state/detail.facade";

@Component({
  selector: "cg-edit-customer-address",
  templateUrl: "./edit-customer-address.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    ReactiveFormsModule,
    DrawerComponent,
    OlbHeadlineComponent,
    OverlayFooterComponent,
    AddressFormComponent
  ]
})
export class EditCustomerAddressComponent implements OnInit, AfterViewInit {
  @Input()
  public customer: CustomerCaseCustomer;

  public form: FormGroup;

  public constructor(
    private readonly detailFacade: DetailFacade,
    private fb: FormBuilder
  ) {}

  public ngOnInit(): void {
    this.form = this.fb.group({
      address: this.fb.group({})
    });
  }

  public ngAfterViewInit(): void {
    this.setFormValues();
  }

  public setFormValues(): void {
    const initialAddressForm = {
      title: this.customer.customerTitle,
      firstname: this.customer.customerFirstName,
      lastname: this.customer.customerLastName,
      zip: this.customer.customerShipZipCode,
      street: this.customer.customerShipAddressLine2 ?? this.customer.customerShipAddressLine1,
      city: this.customer.customerShipCity,
      country: this.customer.customerShipCountry
    };
    this.form.controls.address.patchValue(initialAddressForm);
  }

  public saveForm(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.detailFacade.updateAddressOfCustomer(this.form.controls.address.value);
  }
}
