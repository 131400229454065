import { createAction, props } from "@ngrx/store";
import type { CustomerEditDialog } from "../enum";
import type { CancelAppointmentStatus } from "../enum/CancelAppointmentStatus.enum";
import type { AppointmentCancelation } from "../interfaces/appointment-cancelation.interface";
import type { MyCarglassDetail } from "../interfaces/my-carglass-detail.interface";
import type { MyCarglassUpdateAppointment } from "../interfaces/my-carglass-update-appointment.interface";
import type { ServiceCenter } from "@cg/core/interfaces";
import type { MyCarglassNotificationStatus } from "@cg/my-carglass-login";
import type { CustomerCaseEditContact, SetAppointmentPayload } from "@cg/shared";

export const setMycarglassDetailObject = createAction(
  "[Detail/API] Set MyCarglass Detail Object",
  props<{ payload: MyCarglassDetail }>()
);

export const getCustomerCase = createAction(
  "[Detail/API] Get Customer Case",
  props<{ payload: { customerCaseId: string } }>()
);

export const getCustomerCaseFailureReceived = createAction(
  "[Detail/API] Get Customer Case On Refresh Failure Received"
);

export const logout = createAction(
  "[Detail/API] Log Out",
  props<{
    payload: {
      isAutoLogout?: boolean;
      isCancelLogout?: boolean;
      customRouting?: string[];
    };
  }>()
);

export const resetState = createAction("[Detail/API] Reset State");

export const setNotificationStatus = createAction(
  "[Detail/API] Set Toast Status",
  props<{ payload: MyCarglassNotificationStatus }>()
);

export const updateAppointment = createAction(
  "[Detail/API] Update Appointment",
  props<{ payload: MyCarglassUpdateAppointment }>()
);

export const updateAppointmentSuccessReceived = createAction(
  "[Detail/API] Update Appointment Success Received",
  props<{ payload: SetAppointmentPayload }>()
);

export const updateAppointmentFailureReceived = createAction("[Detail/API] Update Appointment Failure Received");

export const updateCustomerContact = createAction(
  "[Detail/API] Update Customer Contact",
  props<{ payload: CustomerCaseEditContact }>()
);

export const updateCustomerContactSuccess = createAction(
  "[Detail/API] Update Customer Contact Success",
  props<{ payload: CustomerCaseEditContact }>()
);

export const updateCustomerContactFailure = createAction(
  "[Detail/API] Update Customer Contact Failure",
  props<{ error: string }>()
);

export const getServiceCentersDetails = createAction(
  "[Detail/API] Get Service Center Details",
  props<{ payload: string[] }>()
);

export const getServiceCentersDetailsSuccessReceived = createAction(
  "[Detail/API] Get Service Center Details Success Received",
  props<{ payload: ServiceCenter }>()
);

export const getServiceCentersDetailsFailureReceived = createAction(
  "[Detail/API] Get Service Center Details Failure Received",
  props<{ payload: string }>()
);

export const setCustomerEditDialogVisibleType = createAction(
  "[Detail/API] Set Customer Edit Dialog Visible",
  props<{ payload: CustomerEditDialog }>()
);

export const cancelAppointment = createAction(
  "[Detail/API] Cancel Appointment",
  props<{ payload: AppointmentCancelation }>()
);

export const cancelAppointmentStatus = createAction(
  "[Detail/API] Cancel Appointment Status Changed",
  props<{ payload: CancelAppointmentStatus | null }>()
);
