<cg-round-corner-tile>
  <div class="relative px-6 py-4 d:px-8 d:py-6">
    @if ({ addressChanged: addressChanged$ | async, contactChanged: contactChanged$ | async }; as data) {
      @if (data.addressChanged || data.contactChanged) {
        <div class="absolute -left-2 top-0 -translate-y-1/2">
          <cg-my-carglass-badge [text]="'myCarglass.badge.customer' | transloco"></cg-my-carglass-badge>
        </div>
      }
      <cg-paragraph class="mb-4 block"
        ><strong>{{ "myCarglass.detail.additionalAppointmentDetails" | transloco }}</strong></cg-paragraph
      >
      <div class="h-[1px] w-full bg-cg-anthracite-light-80"></div>
      <div class="flex flex-col gap-x-0 d:flex-row d:gap-x-[72px]">
        <div class="flex-1">
          @for (contentCard of contentCardsPartsLeftColumn; track contentCard) {
            <cg-content-card
              class="d:[&>div>div]:last:pb-0"
              [ngClass]="{
                '[&>#content-card-address>div>div>div>cg-richtext>div]:text-cg-green': data.addressChanged,
                '[&>#content-card-contact>div>div>div>cg-richtext>div]:text-cg-green': data.contactChanged
              }"
              data-cs-mask
              [content]="contentCard"
            ></cg-content-card>
          }
        </div>
        <div class="mr-[18px] flex-1">
          @for (contentCard of contentCardsRightColumn; track contentCard) {
            <cg-content-card
              class="[&>div>div]:last:pb-0"
              [content]="contentCard"
              [contentUnderDescriptionTemplateRef]="contentCard.id === 'payment' ? paymentAdditionalContent : null"
              [attr.data-pw-mask]="contentCard.id === 'damageDate' ? true : null"
            >
            </cg-content-card>
          }
        </div>
      </div>
    }
  </div>
</cg-round-corner-tile>

<ng-template #paymentAdditionalContent>
  <div class="flex flex-wrap gap-2">
    @for (icon of paymentIcons; track icon) {
      <cg-icon class="h-[50px] w-[50px]" [content]="icon"></cg-icon>
    }
  </div>
</ng-template>
