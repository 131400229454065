import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: "cg-my-carglass-badge",
  templateUrl: "./my-carglass-badge.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: []
})
export class MyCarglassBadgeComponent {
  @Input()
  public text: string;
}
