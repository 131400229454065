<ng-template #titleTemplate>
  <div class="flex w-full items-center justify-between py-4">
    <cg-paragraph class="my-carglass-actions--title">{{ "myCarglass.detail.actions.title" | transloco }} </cg-paragraph>
    <cg-icon class="h-4 w-4" [content]="accordionIcon" [ngClass]="{ expanded: expanded }"></cg-icon>
  </div>
</ng-template>

<ng-template #contentTemplateDesktop>
  <div class="h-[1px] w-full bg-cg-anthracite-light-80"></div>
  <div class="mb-4 mt-6 flex flex-col justify-start gap-4 d:mb-8 d:mt-4 d:flex-row d:justify-between d:gap-12">
    <button
      cg-base-button
      class="w-full"
      [id]="'my-carglass-edit-actions-change-appointment'"
      [btnText]="'myCarglass.detail.actions.changeAppointment' | transloco"
      (click)="changeAppointment()"
    ></button>
    <button
      cg-base-button
      class="w-full"
      [id]="'my-carglass-edit-actions-remove-appointment'"
      [btnText]="'myCarglass.detail.actions.removeAppointment' | transloco"
      (click)="cancelAppointment()"
    ></button>
  </div>
  <cg-ccc-phone-info
    class="mb-4"
    phoneInfoTextKey="cccOpeningHours.byPhone.myCarglass.detail"
    [phoneNumber]="PhoneNumber.MY_CARGLASS_DETAIL"
  ></cg-ccc-phone-info>
</ng-template>

<cg-round-corner-tile>
  <cg-expansion-panel
    class="px-6 d:px-8"
    data-cy="my-carglass-actions"
    [titleTemplate]="titleTemplate"
    [contentTemplate]="contentTemplateDesktop"
    [expanded]="expanded"
    (accordionToggled)="accordionToggled()"
  ></cg-expansion-panel>
</cg-round-corner-tile>
