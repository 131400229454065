import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ParagraphComponent } from "@cg/core/ui";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { CccPhoneInfoComponent, CtaVariation, DrawerComponent, OverlayFooterComponent, PhoneNumber } from "@cg/shared";
import type { Cta, Icon, Paragraph } from "@cg/content-api/typescript-interfaces";

@Component({
  selector: "cg-base-info-overlay",
  templateUrl: "./base-info-overlay.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [OlbHeadlineComponent, DrawerComponent, ParagraphComponent, CccPhoneInfoComponent, OverlayFooterComponent]
})
export class BaseInfoOverlayComponent {
  @Input()
  public phone: PhoneNumber;

  @Input()
  public title: string;

  @Input()
  public text: Paragraph;

  @Input()
  public icon: Icon;

  public readonly CANCEL_TITLE = "myCarglass.detail.overlays.baseInfoOverlayCancelBtn";

  @Input()
  public hideSaveBtn = true;

  @Input()
  public submitButtonTranslationKey: string;

  @Input()
  public cancelCta: Cta = {
    id: "info-overlay-cancel-cta",
    title: this.CANCEL_TITLE,
    icon: null,
    link: {
      href: null,
      title: this.CANCEL_TITLE,
      text: this.CANCEL_TITLE,
      routerLink: false
    },
    arrowDirection: "right",
    variation: CtaVariation.PRIMARY,
    ngTemplate: "cgCta"
  };

  @Output()
  public submitButtonClicked: EventEmitter<void> = new EventEmitter<void>();

  public emitSubmitButtonClicked() {
    this.submitButtonClicked.emit();
  }
}
