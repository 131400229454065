import { distinctUntilChanged, filter, map, pairwise } from "rxjs/operators";
import type { DetailFacade } from "../+state/detail.facade";
import type { CustomerCase, CustomerCaseEditContact } from "@cg/shared";
import { wasDataChanged } from "../operators/data-changed.operator";

export function getDateChanged$(detailFacade: DetailFacade) {
  return detailFacade.customerCase$.pipe(
    filter(Boolean),
    map(({ appointment: { availabilityPeriodEnd, availabilityPeriodStart } }: CustomerCase) => ({
      availabilityPeriodEnd,
      availabilityPeriodStart
    })),
    wasDataChanged()
  );
}

export function getLocationChanged$(detailFacade: DetailFacade) {
  return detailFacade.customerCase$.pipe(
    filter(Boolean),
    map(({ appointment: { street, postalCode, city } }: CustomerCase) => ({
      street,
      postalCode,
      city
    })),
    wasDataChanged()
  );
}

export function getAddressChanged$(detailFacade: DetailFacade) {
  return detailFacade.customerCaseEditContact$.pipe(
    filter(Boolean),
    map(
      ({ title, name, firstName, lastName, address: { street, zipCode, city, country } }: CustomerCaseEditContact) => ({
        title,
        name,
        firstName,
        lastName,
        address: { street, zipCode, city, country }
      })
    ),
    wasDataChanged()
  );
}

export function getContactChanged$(detailFacade: DetailFacade) {
  return detailFacade.customerCaseEditContact$.pipe(
    filter(Boolean),
    pairwise(),
    distinctUntilChanged(
      (
        [_prev, curr]: [CustomerCaseEditContact, CustomerCaseEditContact],
        [_prev2, curr2]: [CustomerCaseEditContact, CustomerCaseEditContact]
      ) =>
        curr.email === curr2.email &&
        curr.phone.mobile === curr2.phone.mobile &&
        curr.phone.landline === curr2.phone.landline
    ),
    map(
      ([prev, current]: [CustomerCaseEditContact, CustomerCaseEditContact]) =>
        prev.email !== current.email ||
        ![current.phone.mobile, current.phone.landline].every(
          (i: string) => prev.phone.mobile === i || prev.phone.landline === i
        )
    )
  );
}
