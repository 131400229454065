import { NgClass } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { take } from "rxjs";
import { TranslocoPipe } from "@jsverse/transloco";
import { IconComponent, ParagraphComponent, RoundCornerTileComponent } from "@cg/core/ui";
import { accordionChevronDownIcon } from "@cg/icon";
import {
  BaseButtonComponent,
  CccPhoneInfoComponent,
  CustomerCase,
  ExpansionPanelComponent,
  OverlayService,
  PhoneNumber
} from "@cg/shared";
import { AppointmentUtilityService } from "../../../../services/appointment-utility.service";
import { MyCarglassTrackingService } from "../../../../services/my-carglass-tracking.service";
import { EditAppointmentComponent } from "../my-carglass-overlays";
import { CancelAppointmentComponent } from "../my-carglass-overlays/cancel-appointment/cancel-appointment.component";
import { EditAppointmentByCallbackComponent } from "../my-carglass-overlays/edit-appointment-by-callback/edit-appointment-by-callback.component";

@Component({
  selector: "cg-my-carglass-actions",
  templateUrl: "./my-carglass-actions.component.html",
  styleUrls: ["./my-carglass-actions.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    TranslocoPipe,
    ParagraphComponent,
    IconComponent,
    BaseButtonComponent,
    CccPhoneInfoComponent,
    RoundCornerTileComponent,
    ExpansionPanelComponent
  ]
})
export class MyCarglassActionsComponent {
  public readonly PhoneNumber = PhoneNumber;

  @Input()
  public customerCase: CustomerCase;

  public readonly accordionIcon = accordionChevronDownIcon;

  public expanded = false;

  public constructor(
    private readonly overlayService: OverlayService,
    private readonly trackingService: MyCarglassTrackingService,
    private readonly appointmentUtilityService: AppointmentUtilityService
  ) {}

  public accordionToggled(): void {
    this.expanded = !this.expanded;
  }

  public cancelAppointment(): void {
    this.overlayService.open(CancelAppointmentComponent, { customerCase: this.customerCase });
    this.trackingService.trackCancelAppointmentIntent();
  }

  public changeAppointment(): void {
    this.appointmentUtilityService
      .isAppointmentChangeableInMyCarglass$(this.customerCase)
      .pipe(take(1))
      .subscribe((isChangeable: boolean) => {
        this.overlayService.open(isChangeable ? EditAppointmentComponent : EditAppointmentByCallbackComponent, {
          customerCase: this.customerCase
        });
      });

    this.trackingService.trackFieldEvent({ eventAction: "edit-appointment", eventLabel: "my-carglass/detail" });
  }
}
