import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { merge, Observable } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import {
  HeadlineComponent,
  HeadlineType,
  IconComponent,
  ParagraphComponent,
  RoundCornerTileComponent
} from "@cg/core/ui";
import { confirmedIcon } from "@cg/icon";
import { compromisedPartTranslationKey, Damage, RequiredService } from "@cg/shared";
import { DetailFacade } from "../../../../+state/detail.facade";
import { getDateChanged$, getLocationChanged$ } from "../../../../utils";

@Component({
  selector: "cg-my-carglass-detail-header",
  templateUrl: "./my-carglass-detail-header.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, TranslocoPipe, HeadlineComponent, ParagraphComponent, IconComponent, RoundCornerTileComponent]
})
export class MyCarglassDetailHeaderComponent implements OnInit, OnChanges {
  public readonly HeadlineType = HeadlineType;

  @Input()
  public damages: Damage[];

  @Input()
  public customerName: string;

  @Input()
  public orderNumber: string | null;

  public readonly confirmedIcon = confirmedIcon;

  public damageTypeDescription$: Observable<string>;
  public orderNumberInfo$: Observable<string>;

  public hasAppointmentChanged$ = merge(
    getDateChanged$(this.detailFacade),
    getLocationChanged$(this.detailFacade)
  ).pipe(filter(Boolean));

  public constructor(
    private readonly translocoService: TranslocoService,
    private readonly detailFacade: DetailFacade
  ) {}

  public ngOnInit(): void {
    this.damageTypeDescription$ = this.getDamageTypeDescription$();

    this.orderNumberInfo$ = this.translocoService.selectTranslate("myCarglass.detail.orderNumberInfo", {
      orderNumber: this.orderNumber
    });
  }

  private getDamageTypeDescription$(): Observable<string> {
    if (this.damages?.length > 1) {
      return this.translocoService.selectTranslate("myCarglass.detail.multipleDamages");
    }

    return this.translocoService.selectTranslate(compromisedPartTranslationKey[this.damages[0].compromisedPart]).pipe(
      mergeMap((compromisedPart: string) =>
        this.damages[0].requiredService.toUpperCase() !== RequiredService.REPAIR
          ? this.translocoService.selectTranslate("myCarglass.detail.damageTypeReplace", {
              compromisedPart
            })
          : this.translocoService.selectTranslate("myCarglass.detail.damageTypeRepair", {
              compromisedPart
            })
      )
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.customerName = changes.customerName ? changes.customerName.currentValue : this.customerName;
      this.orderNumber = changes.orderNumber ? changes.orderNumber.currentValue : this.orderNumber;
    }
  }
}
