import { DatePipe } from "@angular/common";
import { Component, DestroyRef, inject, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { combineLatest } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { TranslocoPipe } from "@jsverse/transloco";
import { GenericOpeningHours } from "@cg/core/interfaces";
import { ParagraphComponent } from "@cg/core/ui";
import { CustomerCaseAppointment, OpeningHoursComponent } from "@cg/shared";
import { DetailFacade } from "../../../../../../+state/detail.facade";
import { MyCarglassServiceCenterOpeningHours } from "../../../../../../interfaces";

@Component({
  selector: "cg-my-carglass-service-center-openings-hours",
  templateUrl: "./my-carglass-service-center-openings-hours.component.html",
  styleUrls: ["./my-carglass-service-center-openings-hours.component.scss"],
  standalone: true,
  imports: [TranslocoPipe, ParagraphComponent, OpeningHoursComponent, DatePipe],
  providers: [DatePipe]
})
export class MyCarglassServiceCenterOpeningsHoursComponent implements OnInit {
  public destroyRef = inject(DestroyRef);
  public mappedOpeningHoursList: GenericOpeningHours[] = [];
  public openingHoursToday: GenericOpeningHours;
  public appointmentDate: Date;

  public constructor(private readonly detailFacade: DetailFacade) {}

  public ngOnInit(): void {
    combineLatest([this.detailFacade.openingHours$, this.detailFacade.appointment$])
      .pipe(
        filter(
          ([openingHours, appointment]: [MyCarglassServiceCenterOpeningHours, CustomerCaseAppointment]) =>
            !!openingHours?.allOpeningHours && !!appointment
        ),
        tap(([openingHours, appointment]: [MyCarglassServiceCenterOpeningHours, CustomerCaseAppointment]) => {
          this.mappedOpeningHoursList = openingHours.allOpeningHours;
          this.openingHoursToday = openingHours.appointmentDayOpeningHours;
          const dateOfAppointment = new Date(appointment.jobStart);
          this.appointmentDate = dateOfAppointment;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }
}
