@if (appointmentData) {
  @if (hasSelectedAppointment) {
    <cg-drawer [content]="appointmentDetail" [footer]="appointmentDetailFooter" [showCloseIcon]="false"></cg-drawer>
  }
  @if (!hasSelectedAppointment) {
    <cg-drawer
      [headline]="appointmentListTitle"
      [content]="appointmentList"
      [footer]="appointmentListFooter"
      [showCloseIcon]="false"
    ></cg-drawer>
  }
  <ng-template #appointmentListTitle>
    <cg-olb-headline [title]="'myCarglass.detail.overlays.editAppointmentTitle' | transloco"></cg-olb-headline>
  </ng-template>
  <ng-template #appointmentList>
    <div class="-mt-8 t:-mt-6">
      <form [formGroup]="form">
        <cg-appointment-search
          class="block w-full [&>div>form>div>cg-appointment-filter]:block [&>div>form>div>cg-appointment-filter]:pt-1.5 [&>div>form>div]:static [&>div>form>div]:pt-10"
          [appointmentData]="appointmentData"
        >
        </cg-appointment-search>
      </form>
    </div>
  </ng-template>
  <ng-template #appointmentDetail>
    <div class="mt-4">
      <cg-appointment-detail [appointmentData]="appointmentData"></cg-appointment-detail>
    </div>
  </ng-template>
}

<ng-template #appointmentListFooter>
  <cg-overlay-footer
    [hideSaveBtn]="true"
    [hideCloseBtn]="true"
    [customButtons]="calloutFooterButtons"
    (customButtonClicked)="calloutClicked()"
  ></cg-overlay-footer>
</ng-template>

<ng-template #appointmentDetailFooter>
  <cg-overlay-footer
    [customButtons]="detailFooterButtons"
    (customButtonClicked)="goBackToAppointmentList()"
    [hideCloseBtn]="true"
    (submitButtonClicked)="saveForm()"
  ></cg-overlay-footer>
</ng-template>
