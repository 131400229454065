@if (openingHoursToday) {
  <cg-paragraph
    [content]="{
      text: 'myCarglass.detail.serviceCenterOpeningHours.title' | transloco,
      ngTemplate: 'cgParagraph'
    }"
  ></cg-paragraph>
  <ng-template #titleTemplate>
    <div class="pb-2">
      <cg-paragraph class="[&>p]:text-[16px] [&>p]:leading-[20px] [&>p]:text-cg-anthracite">
        <div class="flex flex-wrap gap-x-2">
          <strong>{{ appointmentDate | date: "EEEE dd.MM." }}</strong>
          <cg-paragraph
            class="[&>p]:text-[16px] [&>p]:leading-[20px] [&>p]:text-cg-anthracite"
            [content]="{
              text: 'openingHours.fromTo' | transloco: { from: openingHoursToday.from, to: openingHoursToday.to },
              ngTemplate: 'cgParagraph'
            }"
          ></cg-paragraph>
          @if (openingHoursToday.from2) {
            <cg-paragraph
              class="[&>p]:text-[16px] [&>p]:leading-[20px] [&>p]:text-cg-anthracite"
              [content]="{
                text: 'openingHours.fromTo' | transloco: { from: openingHoursToday.from2, to: openingHoursToday.to2 },
                ngTemplate: 'cgParagraph'
              }"
            ></cg-paragraph>
          }
        </div>
      </cg-paragraph>
    </div>
  </ng-template>
  <cg-opening-hours
    [openingHoursList]="mappedOpeningHoursList"
    [titleTemplateRef]="titleTemplate"
    [reorderOpeningHours]="false"
  ></cg-opening-hours>
}
