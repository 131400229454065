import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { take } from "rxjs/operators";
import { MyCarglassNotificationStatus } from "@cg/my-carglass-login";
import { AddressForm, Appointment, CustomerCase, CustomerCaseEditContact, CustomerContactForm } from "@cg/shared";
import { CustomerEditDialog } from "../enum";
import { CancelAppointmentStatus } from "../enum/CancelAppointmentStatus.enum";
import { MyCarglassUpdateAppointment } from "../interfaces/my-carglass-update-appointment.interface";
import * as DetailActions from "./detail.actions";
import * as DetailSelectors from "./detail.selectors";

@Injectable({
  providedIn: "root"
})
export class DetailFacade {
  public damages$ = this.store.pipe(select(DetailSelectors.getDamages));
  public customerCase$ = this.store.pipe(select(DetailSelectors.getCustomerCase));
  public notificationStatus$ = this.store.pipe(select(DetailSelectors.getToastStatus));
  public customerCaseEditContact$ = this.store.pipe(select(DetailSelectors.getCustomerCaseEditContact));
  public appointment$ = this.store.pipe(select(DetailSelectors.getAppointment));
  public serviceCenterDetails$ = this.store.pipe(select(DetailSelectors.getServiceCenterDetails));
  public customerEditDialogVisibleType$ = this.store.pipe(select(DetailSelectors.getCustomerEditDialogVisibleType));
  public openingHours$ = this.store.pipe(select(DetailSelectors.getOpeningHours));
  public cancelAppointmentStatus$ = this.store.pipe(select(DetailSelectors.getCancelAppointmentStatus));

  public constructor(private readonly store: Store) {}

  public getCustomerCaseOnRefresh(customerCaseId: string): void {
    this.store.dispatch(DetailActions.getCustomerCase({ payload: { customerCaseId } }));
  }

  public getServiceCentersDetails(kst: string): void {
    this.store.dispatch(DetailActions.getServiceCentersDetails({ payload: [kst] }));
  }

  public updateAddressOfCustomer(addressForm: AddressForm): void {
    this.customerCase$.pipe(take(1)).subscribe(async (customerCase: CustomerCase) => {
      const customer = customerCase.customer;

      const phone1 = customer.contactEmail ? customer.contactPhone1 : customer.customerPhone1;
      const phone2 = customer.contactEmail ? customer.contactPhone2 : customer.customerPhone2;

      const updateCustomerRequest: CustomerCaseEditContact = {
        title: addressForm.title,
        name: `${addressForm.firstname} ${addressForm.lastname}`,
        firstName: addressForm.firstname,
        lastName: addressForm.lastname,
        isOrganization: customerCase.organization === "Y",
        email: customer.contactEmail ?? customer.customerEmail,
        phone: {
          ...(await this.getPhoneTypeObject(phone1)),
          ...(await this.getPhoneTypeObject(phone2))
        } as { mobile: string; landline: string },
        address: {
          street: addressForm.street,
          zipCode: addressForm.zip,
          city: addressForm.city,
          country: addressForm.country
        }
      };

      this.store.dispatch(DetailActions.updateCustomerContact({ payload: updateCustomerRequest }));
    });
  }

  public updateContactOfCustomer(contactForm: CustomerContactForm): void {
    this.customerCase$.pipe(take(1)).subscribe((customerCase: CustomerCase) => {
      const customer = customerCase.customer;
      const street = customer.customerShipAddressLine2 ?? customer.customerShipAddressLine1;
      const title = customer.customerTitle;

      const firstName = customer.contactFirstName ? customer.contactFirstName : customer.customerFirstName;
      const lastName = customer.contactLastName ? customer.contactLastName : customer.customerLastName;

      const name = `${firstName} ${lastName}`;

      const updateCustomerRequest: CustomerCaseEditContact = {
        title,
        name,
        firstName,
        lastName,
        isOrganization: customerCase.organization === "Y",
        email: contactForm.email,
        phone: {
          mobile: contactForm.mobile,
          landline: contactForm.landline?.length === 0 ? null : contactForm.landline
        },
        address: {
          street,
          zipCode: customer.customerShipZipCode,
          city: customer.customerShipCity,
          country: customer.customerShipCountry
        }
      };

      this.store.dispatch(DetailActions.updateCustomerContact({ payload: updateCustomerRequest }));
    });
  }

  public updateAppointmentOfCustomer(appointment: Appointment, prevAppointmentStartDate: string): void {
    const payload: MyCarglassUpdateAppointment = {
      setAppointmentPayload: { ...appointment, source: "MYCARGLASS" },
      prevAppointmentStartDate: prevAppointmentStartDate
    };
    this.store.dispatch(DetailActions.updateAppointment({ payload }));
  }

  public logout(
    args: {
      isAutoLogout?: boolean;
      isCancelLogout?: boolean;
      customRouting?: string[];
    } = { isAutoLogout: false, isCancelLogout: false }
  ): void {
    this.store.dispatch(DetailActions.logout({ payload: args }));
  }

  public setNotificationStatus(status: MyCarglassNotificationStatus | null): void {
    this.store.dispatch(DetailActions.setNotificationStatus({ payload: status }));
  }

  public setCustomerEditDialogVisibleType(customerEditDialogVisibleType: CustomerEditDialog): void {
    this.store.dispatch(DetailActions.setCustomerEditDialogVisibleType({ payload: customerEditDialogVisibleType }));
  }

  public cancelAppointment(cancelId: string, reason?: string): void {
    this.store.dispatch(DetailActions.cancelAppointment({ payload: { reason, cancelId } }));
  }

  public setCancelAppointmentStatus(cancelAppointmentStatus: CancelAppointmentStatus) {
    this.store.dispatch(DetailActions.cancelAppointmentStatus({ payload: cancelAppointmentStatus }));
  }

  private async getPhoneTypeObject(phoneNumber: string): Promise<{ mobile?: string; landline?: string }> {
    if (!phoneNumber || phoneNumber.trim().length === 0) {
      return {};
    }

    const { parsePhoneNumber } = await import("libphonenumber-js/max");

    const type = parsePhoneNumber(phoneNumber, "DE").getType();
    const key = type === "MOBILE" ? "mobile" : "landline";

    return { [key]: phoneNumber };
  }
}
